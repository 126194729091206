import React, { useState, useEffect } from "react";
import SpotifyWebApi from "spotify-web-api-js";
import {
  getArtistData,
  getArtistImage,
  getFanProfileById,
  notifyUser,
  subscribeUserToMultipleArtists,
  updateProfileData,
  getFollowedArtists,
  syncAndUpdateSpotifyArtistData,
  syncAndUpdateAppleArtistData,
} from "../rtsvrf-actions/Actions";
import ReactGA from "react-ga4";
import Loading from "../rtsvrf-components/Loading";
import Artists from "../rtsvrf-components/Artists";
import Profile from "../rtsvrf-components/Profile";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Navbar from "../rtsvrf-components/Navbar";
import { useParams, useSearchParams } from "react-router-dom";
import {
  CONNECT_SPOTIFY,
  HomePagePreference,
  SYNC_SPOTIFY,
  userNotificationMapping,
} from "../rtsvrf-utils/Constants";
import { logSuccess } from "../rtsvrf-actions/Logging";
import { Tab, Tabs } from "../rtsvrf-components/Tabs";
import AvInbox from "../rtsvrf-components/Inbox";
import { toast } from "react-toastify";
import SyncSpotify from "../rtsvrf-components/SyncSpotify";
import { promptSyncSpotify } from "../rtsvrf-utils/Helpers";

const spotifyApi = new SpotifyWebApi();
function Fanfeed({ userIpData, music }) {
  const [spotifyConnection, setSpotifyConnection] = useState(false);
  const [spotifyToken, setSpotifyToken] = useState();
  const [connecting, setConnecting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [homePagePreference, setHomePagePreference] = useState();
  const [artistsLoading, setArtistsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [followedArtists, setFollowedArtists] = useState(null);
  const [artistImages, setArtistImages] = useState([]);
  const { id } = useParams();
  const [enableSpotifySyncPrompt, setEnableSpotifySyncPrompt] = useState(null);
  const [spotifySyncedArtists, setSpotifySyncedArtists] = useState([]);
  const [appleSyncedArtists, setAppleSyncedArtists] = useState([]);
  const [isSpotifySynced, setIsSpotifySynced] = useState(false);
  const [isAppleSynced, setIsAppleSynced] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    console.log(id, "idddd");
    reloadData();
    if (localStorage.getItem("spotifyToken")) {
      setSpotifyToken(localStorage.getItem("spotifyToken"));
      localStorage.removeItem("spotifyToken");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const reloadData = async () => {
    if (!loading) {
      setLoading(true);

      const userData = await getFanProfileById(id);
      if (
        promptSyncSpotify(
          userData?.artists_sync_frequency,
          userData?.artists_last_sync_timestamp
        )
      )
        setEnableSpotifySyncPrompt(searchParams.get("syncSpotify"));
      // await updateProfileData(id, {
      //   spotify_followed_artists_id: [],
      //   apple_followed_artists_id: [],
      // });
      if (userData) {
        setUser(userData);
        setHomePagePreference(userData.homepage_preference);
        if (
          userData?.spotify_followed_artists_id?.length ||
          userData?.apple_followed_artists_id?.length
        ) {
          const artists = await getFollowedArtists(
            userData?.spotify_followed_artists_id ?? [],
            userData?.apple_followed_artists_id ?? []
          );
          setFollowedArtists(artists);
          const images = await getArtistImage(
            artists?.length ? artists.map((e) => e.rtsvrf_artist_id) : []
          );
          setArtistImages(images);
        } else {
          setFollowedArtists([]);
          setArtistImages([]);
        }
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("setting spotify token 111", spotifyToken, spotifyConnection);
    if (spotifyToken && !spotifyConnection) {
      console.log("setting spotify token 222", spotifyToken, spotifyConnection);
      spotifyApi.setAccessToken(spotifyToken);
      setSpotifyConnection(true);
    }
  }, [spotifyToken, spotifyConnection, id]);

  useEffect(() => {
    console.log(
      "fetchFanData 111",
      spotifyConnection,
      spotifyToken,
      localStorage.getItem("spotifyConnection")
    );
    if (spotifyConnection && spotifyToken && user) {
      console.log(
        "fetchFanData 222",
        spotifyConnection,
        spotifyToken,
        localStorage.getItem("spotifyConnection")
      );
      const spotifyConnectionType = localStorage.getItem("spotifyConnection");
      if (spotifyConnectionType === CONNECT_SPOTIFY) fetchFanData(true);
      if (spotifyConnectionType === SYNC_SPOTIFY) fetchMoreArtists();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, spotifyConnection, spotifyToken, user]);

  const fetchFanData = async (firstConnection) => {
    console.log("fetchFanData entering", firstConnection, artistsLoading, user);
    if (artistsLoading || !user) return;
    setArtistsLoading(true);
    let artists = await getFollowedArtistsFromSpotify();
    console.log(artists, "currently followed artists");
    if (!firstConnection && user.spotify_followed_artists_id?.length) {
      artists = artists.filter(
        (e) => !user.spotify_followed_artists_id.includes(e.id)
      );
    }
    console.log(artists, "filtered not currently followed artists");
    await logSuccess("Connect Spotify", {
      ...userIpData,
      email: user?.fan_email,
      timestamp: new Date(),
      type: "Fan",
      details: `Connected spotify with ${artists?.length} followed artists`,
    });
    const artistsData = await syncAndUpdateSpotifyArtistData(
      artists,
      id,
      userIpData,
      firstConnection ? "SPOTIFY" : "FLYWHEEL"
    );
    setFollowedArtists(artistsData);
    let followedArtistList = [...(user.spotify_followed_artists_id ?? [])];

    let artistsSubscriptionList = [];
    artistsData?.forEach((e) => {
      followedArtistList.push(e.artist_spotfy_id);

      artistsSubscriptionList.push({
        list_id: e.rtsvrf_artist_id,
        list_name: `${e.artist_name}_all_followers`,
        list_description: `${e.artist_name}_all_followers`,
      });
    });

    //

    const result = await updateProfileData(id, {
      spotify_followed_artists_id: followedArtistList,
      ...(firstConnection
        ? { spotify_stream_connection_timestamp: new Date() }
        : {}),
    });
    if (firstConnection) {
      await notifyUser(user.rtsvrf_fan_uuid, {
        details: { UserName: user?.fan_name },
        type: userNotificationMapping.newFollower,
        name: `${userNotificationMapping.newFollower}${
          user?.fan_name || user.fan_email
        }`,
      });
    }
    reloadData();
    //

    await subscribeUserToMultipleArtists(id, artistsSubscriptionList);

    localStorage.removeItem("spotifyConnection");
    setArtistsLoading(false);
    return followedArtistList;
  };

  const getFollowedArtistsFromSpotify = async () => {
    const response = await spotifyApi.getFollowedArtists({ limit: 50 });
    if (response.artists) {
      return response.artists?.items;
    } else return [];
  };

  const fetchMoreArtists = async () => {
    if (!user) return;
    // Sync existing followed artists
    const newFollowedArtists = await fetchFanData(false);
    // Sync top artists and recently played tracks
    const artistsResponse = await spotifyApi.getMyTopArtists({ limit: 50 });
    const tracksResponse = await spotifyApi.getMyRecentlyPlayedTracks({
      limit: 50,
    });
    const artists = [];
    artistsResponse.items.forEach((e) => {
      if (
        !artists.find((artist) => artist.id === e.id) &&
        !newFollowedArtists?.includes(e.id)
      )
        artists.push(e);
    });
    tracksResponse.items.forEach((e) => {
      e.track.artists.forEach((artist) => {
        if (
          !artists.find((a) => a.id === artist.id) &&
          !newFollowedArtists?.includes(artist.id)
        )
          artists.push(artist);
      });
    });
    setSpotifySyncedArtists(artists);
    setIsSpotifySynced(true);
    localStorage.removeItem("spotifyConnection");
    console.log(artistsResponse, tracksResponse, "artistsResponse", artists);
  };

  const authorizeAppleMusic = async () => {
    if (connecting) return;
    if (music) {
      setConnecting(true);
      ReactGA.event({
        category: "Connect Apple",
        action: "click",
        label: "Connect Apple",
      });
      try {
        await music.authorize();
        music.authorize().then(async (res) => {
          setConnecting(true);
          await saveAppleFollowedArtist(true);
          setConnecting(false);
        });
      } catch (error) {
        setConnecting(false);
        toast.error(
          "Something went wrong! Try again after refreshing the page",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        console.log(error, "auth error apple");
      }
      setConnecting(false);
    } else {
      toast.error("Something went wrong! Try again after refreshing the page", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const saveAppleFollowedArtist = async (firstSync) => {
    const artists = await fetchAppleMusicArtists();
    if (artists?.length) {
      if (firstSync)
        await notifyUser(user.rtsvrf_fan_uuid, {
          details: { UserName: user?.fan_name },
          type: userNotificationMapping.newFollower,
          name: `${userNotificationMapping.newFollower}${
            user?.fan_name || user.fan_email
          }`,
        });
      let artistsData = await syncAndUpdateAppleArtistData(
        artists,
        id,
        userIpData,
        "APPLE"
      );
      console.log(
        artistsData,
        "artistsData before filter",
        user?.apple_followed_artists_id,
        user?.spotify_followed_artists_id
      );
      if (!firstSync) {
        artistsData = artistsData?.filter(
          (artist) =>
            !user?.apple_followed_artists_id?.includes(
              artist.artist_aplmus_id
            ) &&
            !user?.spotify_followed_artists_id?.includes(
              artist.artist_spotfy_id
            )
        );
      }
      console.log(artistsData, "artistsData after filter");

      setFollowedArtists([...followedArtists, ...artistsData]);
      let artistsSubscriptionList = [];
      let followedAppleArtistList = [];
      let followedSpotifyArtistList = [];
      let followedRtsvrfArtistList = [];
      artistsData?.forEach((e) => {
        if (e.artist_aplmus_id) {
          followedAppleArtistList.push(e.artist_aplmus_id);
        } else {
          followedSpotifyArtistList.push(e.artist_spotfy_id);
        }
        followedRtsvrfArtistList.push(e.rtsvrf_artist_id);
        artistsSubscriptionList.push({
          list_id: e.rtsvrf_artist_id,
          list_name: `${e.artist_name}_all_followers`,
          list_description: `${e.artist_name}_all_followers`,
        });
      });
      console.log(artistsData, "follow new apple artists autmaticallly");
      const result = await updateProfileData(id, {
        apple_followed_artists_id: [
          ...(user?.apple_followed_artists_id ?? []),
          ...followedAppleArtistList,
        ],
        apple_stream_connection_timestamp: new Date(),
        ...(followedSpotifyArtistList?.length
          ? {
              spotify_followed_artists_id: [
                ...(user?.spotify_followed_artists_id ?? []),
                ...followedSpotifyArtistList,
              ],
            }
          : {}),
      });
      if (artistsSubscriptionList?.length)
        await subscribeUserToMultipleArtists(id, artistsSubscriptionList);
      const images = await getArtistImage(followedRtsvrfArtistList);
      setArtistImages([...artistImages, ...images]);
    }
    await logSuccess("Connect Apple", {
      ...userIpData,
      email: user?.fan_email,
      timestamp: new Date(),
      type: "Fan",
      details: `Connected Apple with ${artists?.length} followed artists`,
    });
  };

  const fetchAppleMusicArtists = async () => {
    try {
      const data = await music.api.music("v1/me/library/artists");
      console.log(data, "v1/me/library/artists");
      // User's iCloud Music Library Albums
      const artists = data?.data?.data ?? [];
      if (artists?.length) {
        const artistDetails = [];
        const names = artists.map((e) => e?.attributes?.name);
        for (let index = 0; index < names.length; index++) {
          const name = names[index]
            ?.normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          const encodedName = encodeURIComponent(name.replace(/ /g, "+"));
          const artistsCatalog = await music.api.music(
            `v1/catalog/us/search?types=artists&limit=1&term=${encodedName}`
          );
          console.log(artistsCatalog, encodedName);
          if (artistsCatalog?.data?.results?.artists?.data?.length) {
            const artist = artistsCatalog.data.results.artists.data[0];

            artistDetails.push(artist);
          }
        }
        return artistDetails;
      }
      return artists;
    } catch (error) {
      return [];
    }
  };

  const handleSpotifyConnection = async (type) => {
    if (connecting) return;
    ReactGA.event({
      category: type,
      action: "click",
      label: type,
    });
    localStorage.setItem("spotifyConnection", type);

    window.location.href = `https://accounts.spotify.com/authorize?client_id=${process.env.REACT_APP_SPOTIFY_CLIENT_ID}&response_type=token&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}/redirect/&scope=user-read-private%20user-read-email%20user-follow-read%20user-top-read%20user-read-recently-played`;
  };

  const handleAuthorizeApple = async () => {
    if (connecting) return;
    if (music) {
      setConnecting(true);
      ReactGA.event({
        category: "Sync Apple",
        action: "click",
        label: "Sync Apple",
      });
      try {
        await music.authorize();
        music.authorize().then(async (res) => {
          setConnecting(true);
          console.log(res, "res");
          await saveAppleFollowedArtist(false);
          let artists = await fetchAppleTopArtists();
          console.log(artists, "apple top artists");
          console.log(followedArtists, "Followed artists");
          if (followedArtists?.length) {
            artists = artists.filter(
              (artist) =>
                !followedArtists.find(
                  (e) =>
                    e.artist_aplmus_id === artist.id ||
                    e.artist_name === artist?.attributes?.name
                )
            );
          }

          setAppleSyncedArtists(artists);
          setIsAppleSynced(true);

          setConnecting(false);
        });
      } catch (error) {
        setConnecting(false);
        toast.error(
          "Something went wrong! Try again after refreshing the page",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        console.log(error, "auth error apple");
      }
      setConnecting(false);
    } else {
      toast.error("Something went wrong! Try again after refreshing the page", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchAppleTopArtists = async () => {
    try {
      const heavyRotation = await music.api.music(
        "v1/me/history/heavy-rotation"
      );
      console.log(heavyRotation, "v1/me/history/heavy-rotation");

      const tracks = await music.api.music("v1/me/recent/played/tracks");
      console.log(tracks, "v1/me/library/recently-added");
      // User's iCloud Music Library Albums
      const artists = (tracks?.data?.data ?? []).concat(
        heavyRotation?.data?.data ?? []
      );
      console.log(artists, "artists concated");
      if (artists?.length) {
        const artistDetails = [];
        const names = artists
          .map((e) => e?.attributes?.artistName)
          .filter((e) => e);
        for (let index = 0; index < names.length; index++) {
          const name = names[index]
            ?.normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          const encodedName = encodeURIComponent(name.replace(/ /g, "+"));
          const artistsCatalog = await music.api.music(
            `v1/catalog/us/search?types=artists&limit=1&term=${encodedName}`
          );
          console.log(artistsCatalog, encodedName);
          if (artistsCatalog?.data?.results?.artists?.data?.length) {
            const artist = artistsCatalog.data.results.artists.data[0];

            if (!artistDetails.find((e) => e?.id === artist?.id))
              artistDetails.push(artist);
          }
        }
        console.log(artistDetails, "artistDetails");
        return artistDetails;
      }
      return artists;
    } catch (error) {
      return [];
    }
  };

  if (loading || !user) {
    return <Loading />;
  }

  return (
    <div
      className={
        "bg-gradient-to-r from-gray-400 to-gray-600 sm:flex sm:flex-col px-4 overflow-x-hidden"
      }
      style={
        followedArtists &&
        !followedArtists?.length &&
        homePagePreference === HomePagePreference.FollowedArtists
          ? {
              backgroundImage: "url(/background.jpeg)",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: "100%",
            }
          : {}
      }
    >
      <Navbar userIpData={userIpData} />
      {/* <button onClick={() => handleAuthorizeApple()}> Sync Apple</button> */}
      <div className="flex md:flex-col md:justify-center md:items-center">
        {user ? <Profile user={user} userIpData={userIpData} /> : <Loading />}
        <div className="w-full flex flex-col ml-auto mr-auto sm:mr-2 sm:ml-2 items-center min-lg:px-6 my-10">
          {homePagePreference && (
            <Tabs>
              <Tab
                component={
                  <>
                    <Artists
                      handleSpotifyConnection={handleSpotifyConnection}
                      authorizeAppleMusic={authorizeAppleMusic}
                      allowRedirect={true}
                      user={user}
                      connecting={connecting}
                      spotifyConnection={spotifyConnection}
                      followedArtists={followedArtists}
                      artistImages={artistImages}
                    />
                  </>
                }
                currentTab={HomePagePreference.FollowedArtists}
                activeTab={homePagePreference}
                active={
                  homePagePreference === HomePagePreference.FollowedArtists
                }
                handleTab={() => {
                  setHomePagePreference(HomePagePreference.FollowedArtists);
                }}
              >
                My Followed Artists
              </Tab>
              <Tab
                component={
                  <div className="flex flex-col ml-auto mr-auto items-center my-8">
                    <div>
                      {user?.rtsvrf_fan_specific_newsfeed_link && (
                        <iframe
                          width="900"
                          height={"600"}
                          className="rounded-md lg:w-full"
                          title="artist feed"
                          src={`${user?.rtsvrf_fan_specific_newsfeed_link}`}
                          frameborder="0"
                        ></iframe>
                      )}
                    </div>
                  </div>
                }
                currentTab={HomePagePreference.CustomNewsFeed}
                activeTab={homePagePreference}
                active={
                  homePagePreference === HomePagePreference.CustomNewsFeed
                }
                handleTab={() => {
                  setHomePagePreference(HomePagePreference.CustomNewsFeed);
                }}
              >
                My Artist News
              </Tab>
              <Tab
                component={
                  <div className="flex flex-col ml-auto mr-auto items-center">
                    <AvInbox
                      open={true}
                      userId={user?.rtsvrf_fan_uuid}
                      subscriberId={user?.suprsend_subscriber_id}
                    />
                  </div>
                }
                currentTab={HomePagePreference.AVInbox}
                activeTab={homePagePreference}
                active={homePagePreference === HomePagePreference.AVInbox}
                handleTab={() => {
                  setHomePagePreference(HomePagePreference.AVInbox);
                }}
              >
                My AV Inbox
              </Tab>
            </Tabs>
          )}
        </div>
      </div>
      <SyncSpotify
        enableSpotifySyncPrompt={enableSpotifySyncPrompt}
        isSpotifySynced={isSpotifySynced}
        isAppleSynced={isAppleSynced}
        setIsAppleSynced={setIsAppleSynced}
        setIsSpotifySynced={setIsSpotifySynced}
        spotifySyncedArtists={spotifySyncedArtists}
        appleSyncedArtists={appleSyncedArtists}
        setEnableSpotifySyncPrompt={setEnableSpotifySyncPrompt}
        setSearchParams={setSearchParams}
        reloadData={reloadData}
        userIpData={userIpData}
        userId={id}
        connecting={connecting}
        handleAuthorizeApple={handleAuthorizeApple}
        handleSpotifyConnection={handleSpotifyConnection}
      />
    </div>
  );
}

export default withAuthenticationRequired(Fanfeed, {
  onRedirecting: () => <Loading />,
});

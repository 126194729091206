import React from "react";

const AccessError = () => {
  return (
    <div className="w-full h-96 mt-12 flex justify-center items-start">
      <div className="flex flex-col items-center">
        <div className="text-indigo-500 font-bold text-7xl">404</div>

        <div className="font-bold text-3xl xl:text-7xl lg:text-6xl md:text-5xl mt-10">
          Access Denied
        </div>

        <div className="text-gray-400 font-medium text-sm md:text-xl lg:text-2xl mt-8">
          You do not have enough permissions to access this app
        </div>
      </div>
    </div>
  );
};

export default AccessError;

import React, { useState, useEffect } from "react";

import {
  getArtistImage,
  getArtistsByRtsvrfId,
  getDefaultArtistData,
  getFanCustomNewsfeed,
  getFanProfileById,
  getFanSubscription,
  getFollowedArtists,
  getLogHistory,
  getTotalLogHistory,
  subscribeUserToFavouriteArtist,
  updateProfileData,
} from "../rtsvrf-actions/Actions";
import ReactGA from "react-ga4";
import { toast } from "react-toastify";
import Loading from "../rtsvrf-components/Loading";

import Profile from "../rtsvrf-components/Profile";
import { withAuthenticationRequired } from "@auth0/auth0-react";

import { useNavigate, useParams } from "react-router-dom";
import EditProfile from "../rtsvrf-components/EditProfile";
import SelectArtists from "../rtsvrf-components/SelectArtists";
import { Tab, Tabs } from "../rtsvrf-components/Tabs";
import Settings from "../rtsvrf-components/Settings";
import Navbar from "../rtsvrf-components/Navbar";
import Table from "../rtsvrf-components/Table";
import Pagination from "../rtsvrf-components/Pagination";
import Subscription from "../rtsvrf-components/Subscription";
import ArtistRelations from "../rtsvrf-components/ArtistRelations";

function User({ userIpData }) {
  const [loading, setLoading] = useState(false);
  const [editFavArtist1, setEditFavArtist1] = useState(false);
  const [editFavArtist2, setEditFavArtist2] = useState(false);
  const [editFavArtist3, setEditFavArtist3] = useState(false);
  const [editFavArtist4, setEditFavArtist4] = useState(false);
  const [editFavArtist5, setEditFavArtist5] = useState(false);
  const [followedArtists, setFollowedArtists] = useState([]);
  const [defaultArtists, setDefaultArtists] = useState([]);
  const [logs, setLogs] = useState(null);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [favouriteArtists, setFavouriteArtists] = useState([]);
  const [favouriteArtistImages, setFavouriteArtistImages] = useState([]);
  const [user, setUser] = useState(null);
  const [fanSubscription, setFanSubscription] = useState(null);
  const { id } = useParams();
  const listPerPage = 5;
  const [customNewsFeed, setCustomNewsFeed] = useState({
    rtsvrf_fan_uuid: id,
    custom_artist_selection: false,
    custom_artist_activities: false,
    custom_artist_source: false,
    custom_artist_list: [],
    custom_activity_list: [],
    custom_source_list: [],
  });

  let navigate = useNavigate();

  useEffect(() => {
    reloadData();
    getCustomNewsFeed();
    getFanSubscriptionDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const reloadData = async () => {
    if (!loading) {
      setLoading(true);
      const userData = await getFanProfileById(id);
      console.log(userData, "userData");
      if (userData) {
        setUser(userData);
        if (
          userData?.spotify_followed_artists_id?.length ||
          userData?.apple_followed_artists_id?.length
        ) {
          const followedArtistsList = await getFollowedArtists(
            userData?.spotify_followed_artists_id ?? [],
            userData?.apple_followed_artists_id ?? []
          );
          if (followedArtistsList) setFollowedArtists(followedArtistsList);
        }
        const favArtistIds = [];
        if (userData["favourite_1"]) {
          favArtistIds.push(userData["favourite_1"]);
        }
        if (userData["favourite_2"]) {
          favArtistIds.push(userData["favourite_2"]);
        }
        if (userData["favourite_3"]) {
          favArtistIds.push(userData["favourite_3"]);
        }
        if (userData["favourite_4"]) {
          favArtistIds.push(userData["favourite_4"]);
        }
        if (userData["favourite_5"]) {
          favArtistIds.push(userData["favourite_5"]);
        }
        const list = await getFavouriteArtistsData(favArtistIds);
        if (list) setFavouriteArtists(list);

        if (userData) {
          const logsHistory = await getLogHistory(userData.fan_email);
          if (logsHistory?.data) setLogs(logsHistory.data);
        }
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    async function fetchPaginationData() {
      if (!user) return;
      const numberOfRecords = await getTotalLogHistory(user.fan_email);
      console.log(numberOfRecords);
      setTotal(numberOfRecords);
      const logsHistory = await getLogHistory(
        user.fan_email,
        currentPage,
        listPerPage
      );
      if (logsHistory?.data) setLogs(logsHistory.data);
    }
    fetchPaginationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, user]);

  const getCustomNewsFeed = async () => {
    const feed = await getFanCustomNewsfeed(id);
    console.log(feed, "feed");
    if (feed) setCustomNewsFeed(feed);
  };

  const getFanSubscriptionDetails = async () => {
    const subscription = await getFanSubscription(id);
    console.log(subscription, "subscription");
    if (subscription) setFanSubscription(subscription);
  };

  const getFavouriteArtistsData = async (artistIds) => {
    const artists = await getArtistsByRtsvrfId(artistIds);
    if (artists) {
      setFavouriteArtists(artists);

      const artistImages = await getArtistImage(artistIds);
      if (artistImages) setFavouriteArtistImages(artistImages);
    }
  };

  useEffect(() => {
    const getArtistData = async () => {
      const list = await getDefaultArtistData(10);
      if (list) setDefaultArtists(list);
    };
    getArtistData();
  }, []);

  const goBack = () => {
    if (!user) return;
    ReactGA.event({
      category: "Home",
      action: "click",
      label: "Home",
    });
    navigate(`/feed/${user.rtsvrf_fan_uuid}`);
  };

  const handleArtistSelection = async (artist, field) => {
    console.log(artist, field);
    if (loading || !artist || !field || !artist.rtsvrf_artist_id) return;
    setLoading(true);
    ReactGA.event({
      category: "Select favourite artist",
      action: "click",
      label: "Select favourite artist",
    });
    const oldFavArtistId = user[field] || null;
    const result = await updateProfileData(user.rtsvrf_fan_uuid, {
      [field]: artist.rtsvrf_artist_id,
      [field + "_timestamp"]: new Date(),
    });
    setLoading(false);
    setEditFavArtist1(false);
    setEditFavArtist2(false);
    setEditFavArtist3(false);
    setEditFavArtist4(false);
    setEditFavArtist5(false);

    if (result.error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (field === "favourite_1") {
      subscribeUserToFavouriteArtist(
        id,
        artist.rtsvrf_artist_id + "_number1",
        {
          list_name: `${artist?.artist_name}_number1`,
          list_description: `${artist?.artist_name}_number1`,
        },
        oldFavArtistId ? oldFavArtistId + "_number1" : null
      );
      subscribeUserToFavouriteArtist(
        id,
        artist.rtsvrf_artist_id + "_top5",
        {
          list_name: `${artist?.artist_name}_top5`,
          list_description: `${artist?.artist_name}_top5`,
        },
        oldFavArtistId ? oldFavArtistId + "_top5" : null
      );
    } else {
      subscribeUserToFavouriteArtist(
        id,
        artist.rtsvrf_artist_id + "_top5",
        {
          list_name: `${artist?.artist_name}_top5`,
          list_description: `${artist?.artist_name}_top5`,
        },
        oldFavArtistId ? oldFavArtistId + "_top5" : null
      );
    }

    if (!result.error) reloadData();
  };

  return (
    <div
      className={
        "bg-gradient-to-r overflow-x-hidden from-gray-400 to-gray-600 sm:flex sm:flex-col px-4 pb-8"
      }
    >
      <nav className="w-full flex sm:flex-col py-2 justify-between items-center rounded-md">
        <ol className="list-reset flex w-full">
          <li key={1} className="cursor-pointer" onClick={() => goBack()}>
            <p className="text-white transition duration-150 ease-in-out">
              Home
            </p>
          </li>
          <li key={2}>
            <span className="mx-2 text-white">/</span>
          </li>
          <li key={3} className="text-white">
            My Fan Profile
          </li>
        </ol>
        <Navbar userIpData={userIpData} />
      </nav>
      <Tabs>
        <Tab
          component={
            <div className="flex md:flex-col min-2xl:justify-center flex-wrap justify-around md:items-center">
              {user ? (
                <Profile
                  userIpData={userIpData}
                  user={user}
                  redirectToEdit={true}
                  reloadData={reloadData}
                />
              ) : (
                <Loading />
              )}

              {user ? (
                <EditProfile
                  reloadData={reloadData}
                  user={user}
                  userIpData={userIpData}
                />
              ) : null}
            </div>
          }
          active
        >
          My Details
        </Tab>
        <Tab
          component={
            <div className="flex md:flex-col flex-wrap min-2xl:justify-center justify-around md:items-center">
              {user ? (
                <Profile
                  user={user}
                  userIpData={userIpData}
                  redirectToEdit={true}
                  reloadData={reloadData}
                />
              ) : (
                <Loading />
              )}

              <ArtistRelations
                reloadData={reloadData}
                user={user}
                loading={loading}
                userIpData={userIpData}
                favouriteArtists={favouriteArtists}
                favouriteArtistImages={favouriteArtistImages}
                defaultArtists={defaultArtists}
                handleArtistSelection={handleArtistSelection}
                editFavArtist1={editFavArtist1}
                setEditFavArtist1={setEditFavArtist1}
                editFavArtist2={editFavArtist2}
                setEditFavArtist2={setEditFavArtist2}
                editFavArtist3={editFavArtist3}
                setEditFavArtist3={setEditFavArtist3}
                editFavArtist4={editFavArtist4}
                setEditFavArtist4={setEditFavArtist4}
                editFavArtist5={editFavArtist5}
                setEditFavArtist5={setEditFavArtist5}
              />
            </div>
          }
        >
          Artist Relationships
        </Tab>
        <Tab
          component={
            <div className="flex md:flex-col flex-wrap min-2xl:justify-center justify-around md:items-center">
              {user ? (
                <Profile
                  user={user}
                  userIpData={userIpData}
                  redirectToEdit={true}
                  reloadData={reloadData}
                />
              ) : (
                <Loading />
              )}

              {user ? (
                <Settings
                  reloadData={reloadData}
                  user={user}
                  userIpData={userIpData}
                  customNewsFeed={customNewsFeed}
                  followedArtists={followedArtists}
                />
              ) : null}
            </div>
          }
        >
          My Settings
        </Tab>
        {/* <Tab
          component={
            <div className="flex md:flex-col flex-wrap min-2xl:justify-center justify-around md:items-center">
              {user ? (
                <Profile
                  user={user}
                  userIpData={userIpData}
                  redirectToEdit={true}
                  reloadData={reloadData}
                />
              ) : (
                <Loading />
              )}

              {user ? (
                <Subscription
                  loading={loading}
                  fanSubscription={fanSubscription}
                />
              ) : null}
            </div>
          }
        >
          My AV Subscription
        </Tab> */}

        <Tab
          component={
            <div className="flex md:flex-col flex-wrap min-2xl:justify-center justify-around md:items-center">
              {user ? (
                <Profile
                  user={user}
                  userIpData={userIpData}
                  redirectToEdit={true}
                  reloadData={reloadData}
                />
              ) : (
                <Loading />
              )}

              <div className="flex flex-col my-10 min-2xl:ml-28 items-center">
                <Table feed={logs} />
                {logs?.length ? (
                  <div className="py-2 flex items-center justify-center">
                    <Pagination
                      listPerPage={listPerPage}
                      total={total}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          }
        >
          Activity
        </Tab>
      </Tabs>
    </div>
  );
}

export default withAuthenticationRequired(User, {
  onRedirecting: () => <Loading />,
});

import React, { useState, useEffect } from "react";
import {
  getArtistImage,
  getDefaultArtistData,
} from "../rtsvrf-actions/Actions";
import Loading from "../rtsvrf-components/Loading";
import Artists from "../rtsvrf-components/Artists";
import Navbar from "../rtsvrf-components/Navbar";

function Home({ userIpData }) {
  const [loading, setLoading] = useState(false);
  const [defualtArtists, setDefaultArtists] = useState([]);
  const [defualtArtistImages, setDefaultArtistImages] = useState([]);

  useEffect(() => {
    const fetchDefaultData = async () => {
      if (!loading) {
        setLoading(true);
        const list = await getDefaultArtistData(300, 18);
        const idList = list?.map((artist) => artist?.rtsvrf_artist_id);
        if (idList) {
          let images = await getArtistImage(idList);
          images = images.slice(0, 12);
          setDefaultArtistImages(images);
        }
        setDefaultArtists(list);
        setLoading(false);
      }
    };

    fetchDefaultData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={
        "bg-gradient-to-r from-gray-400 to-gray-600 sm:flex sm:flex-col px-4 "
      }
      style={{
        backgroundImage: "url(/background.jpeg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100%",
      }}
    >
      <Navbar userIpData={userIpData} />
      <>
        {loading ? (
          <Loading />
        ) : (
          <Artists
            loading={loading}
            followedArtists={defualtArtists}
            artistImages={defualtArtistImages}
            defaultView={true}
          />
        )}
      </>
    </div>
  );
}

export default Home;

import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getUserData, updateProfileData } from "../rtsvrf-actions/Actions";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import { logSuccess } from "../rtsvrf-actions/Logging";
import suprsend from "@suprsend/web-sdk";
import AvInbox from "./Inbox";
import { toast } from "react-toastify";
import { promptSyncSpotify } from "../rtsvrf-utils/Helpers";

const Navbar = ({ userIpData }) => {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const [userProfile, setUserProfile] = useState(null);
  let navigate = useNavigate();
  useEffect(() => {
    const fetchUserData = async () => {
      if (!user) return;
      const platform = user.sub.includes("spotify")
        ? "SPOTIFY"
        : user.sub.includes("apple")
        ? "APPLE"
        : "GOOGLE";
      const data = {
        fan_email: user.email,
        fan_name: user.name,
        temp_country: user.address || null,
        spotify_followed_artists_id: [],
        apple_followed_artists_id: [],
        fan_picture_link: user.picture || null,
        fan_source_platform: platform,
      };
      const userData = await getUserData(data, userIpData);
      if (userData && userData.fan_account_active) {
        console.log(userData);
        setUserProfile(userData);
        if (!localStorage.getItem("logIn")) {
          await updateProfileData(userData.rtsvrf_fan_uuid, {
            [`${platform.toLocaleLowerCase()}_login_timestamp`]: new Date(),
          });
          await logSuccess("Log In", {
            ...userIpData,
            email: user.email,
            timestamp: new Date(),
            type: "Fan",
            details: data.fan_source_platform,
          });
          suprsend.identify(userData.rtsvrf_fan_uuid);
          suprsend.user.set("name", userData.fan_name);
          suprsend.user.add_email(userData.fan_email);
          if (userData?.phone_number && userData?.dial_prefix) {
            suprsend.user.add_sms(userData.dial_prefix + userData.phone_number);
          }
          localStorage.setItem("logIn", "true");
        }
        const enableSpotifySync = promptSyncSpotify(
          userData?.artists_sync_frequency,
          userData?.artists_last_sync_timestamp
        );
        if (!window.location.pathname.includes("user"))
          navigate(
            `/feed/${userData.rtsvrf_fan_uuid}${
              enableSpotifySync ? "?syncSpotify=true" : ""
            }`
          );
      } else {
        await logSuccess(
          `Attempt to log into deactivated account - ${
            userData.fan_name ?? user.name
          }`,
          {
            ...userIpData,
            email: user.email,
            timestamp: new Date(),
            type: "Fan",
            details: `User with email ${user.email} tried to log into deactivated account`,
          }
        );
        toast.error("You do not have permission to access this app");
        setTimeout(() => {
          handleLogout();
        }, 3000);
      }
    };

    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, navigate]);

  const handleLogin = () => {
    ReactGA.event({
      category: "LogIn",
      action: "click",
      label: "LogIn",
    });
    suprsend.reset();
    localStorage.clear();
    loginWithRedirect();
  };

  const logoutWithRedirect = async () => {
    ReactGA.event({
      category: "LogOut",
      action: "click",
      label: "LogOut",
    });
    await logSuccess("Log Out", {
      ...userIpData,
      email: user.email,
      timestamp: new Date(),
      type: "Fan",
    });
    suprsend.reset();
    handleLogout();
  };
  const handleLogout = () => {
    localStorage.clear();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <>
      <nav className="w-full flex items-center py-4 rounded-md text-right">
        <AvInbox
          userId={userProfile?.rtsvrf_fan_uuid}
          subscriberId={userProfile?.suprsend_subscriber_id}
        />
        {isAuthenticated ? (
          <button
            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold h-10 mt-3 px-4 border border-gray-400 rounded shadow"
            onClick={() => logoutWithRedirect()}
          >
            Logout
          </button>
        ) : (
          <button
            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold h-10 mt-3 px-4 border border-gray-400 rounded shadow"
            onClick={() => handleLogin()}
          >
            Login
          </button>
        )}
      </nav>
    </>
  );
};

export default Navbar;

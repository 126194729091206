import React, { useEffect, useRef, useState } from "react";
import SuprSendInbox from "@suprsend/react-inbox";
import Bell from "../rtsvrf-assets/icons/bell.png";
import BlackBell from "../rtsvrf-assets/icons/bell-btn.png";

const AvInbox = ({ userId, subscriberId, open }) => {
  const bellRef = useRef(null);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (bellRef?.current) {
      setTrigger(true);
    }
  }, [trigger]);

  useEffect(() => {
    if (trigger) {
      bellRef.current.click();
      console.log(bellRef);
    }
  }, [trigger]);

  return (
    <div className="w-full">
      {userId && subscriberId && (
        <SuprSendInbox
          theme={{
            bell: { color: "white" },
          }}
          bellComponent={() =>
            open ? (
              <p ref={bellRef}>
                <img src={Bell} alt="bell" width={"24"} height={"24"} />
              </p>
            ) : (
              <button className="bg-white flex justify-center items-center hover:bg-gray-100 h-10 text-gray-800 font-semibold w-24 border border-gray-400 rounded shadow">
                <img src={BlackBell} alt="bell" width={"24"} height={"24"} />
                Inbox
              </button>
            )
          }
          workspaceKey={process.env.REACT_APP_SUPRSEND_WORKSPACE_KEY}
          subscriberId={subscriberId}
          distinctId={userId}
        />
      )}
    </div>
  );
};

export default AvInbox;

import moment from "moment";
import React, { useEffect, useState } from "react";

const Table = ({ feed }) => {
  const [mobile, setMobile] = useState(window.innerWidth < 640);
  const [expandedRows, setExpandedRows] = useState([]);

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleExpandRow = (itemId) => {
    if (expandedRows.includes(itemId)) {
      setExpandedRows(expandedRows.filter((id) => id !== itemId));
    } else {
      setExpandedRows([...expandedRows, itemId]);
    }
  };

  if (!feed) {
    return (
      <h3 className="text-center text-lg py-6 font-bold text-white">
        Loading...
      </h3>
    );
  }
  if (feed.length === 0) {
    return (
      <h3 className="text-center border border-solid rounded-md text-lg py-6 font-bold text-white">
        No Logs Found
      </h3>
    );
  }
  return (
    <>
      <h3 className="text-center text-lg my-2 font-bold text-white">
        Past Activity
      </h3>
      <div class="w-full flex flex-col min-lg:justify-center min-lg:items-center overflow-x-auto my-4">
        <div class="inline-block py-2 sm:px-6 lg:px-8">
          <div class="overflow-x-auto rounded-lg">
            <table className="border border-solid border-gray max-w-lg rounded-lg divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 ">
                    Created At
                  </th>
                  <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 ">
                    Log
                  </th>
                  <th className="px-6 py-3 text-xs w-96 font-bold text-center text-gray-500 ">
                    Details
                  </th>

                  <th className="px-6 py-3 text-xs font-bold text-center text-gray-500 ">
                    IP
                  </th>
                  <th className="px-6 py-3 text-xs font-bold text-center text-gray-500 ">
                    User Agent
                  </th>

                  <th className="px-6 py-3 text-xs w-96 font-bold text-center text-gray-500 "></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {feed?.map((item) => (
                  <tr key={item.id}>
                    <td
                      width={"100px"}
                      className="px-6 py-4 text-xs font-medium text-white"
                    >
                      <span className="w-36 flex ">
                        {moment(item.timestamp).format("LLL")}
                      </span>
                    </td>
                    <td
                      style={{ width: "200px !important" }}
                      className="px-6 py-4 !w-40 text-xs font-medium text-white"
                    >
                      {item.message}
                    </td>
                    <td
                      className={`px-6 py-4 text-xs font-medium text-white`}
                      style={{
                        width: "200px !important",
                        maxHeight: expandedRows.includes(item.id)
                          ? "none"
                          : "3em",
                        overflow: "hidden",
                      }}
                    >
                      {item.details?.length > 50 &&
                      !expandedRows.includes(item.id) ? (
                        <span className="w-40">
                          {item.details.slice(0, 50)}...
                        </span>
                      ) : (
                        <span className="w-40">{item.details}</span>
                      )}
                    </td>
                    <td className="px-6 py-4 text-xs font-medium text-white whitespace-nowrap">
                      {item.ip_address}
                    </td>
                    <td
                      width={"400px"}
                      className={`px-6 py-4 !w-40 text-xs font-medium text-white`}
                      style={{
                        width: "300px !important",
                        maxHeight: expandedRows.includes(item.id)
                          ? "none"
                          : "3em",
                        overflow: "hidden",
                      }}
                    >
                      {item.user_agent?.length > 50 &&
                      !expandedRows.includes(item.id) ? (
                        <span className="flex w-48">
                          {item.user_agent.slice(0, 50)}...
                        </span>
                      ) : (
                        <span className="w-48 flex">{item.user_agent}</span>
                      )}
                    </td>

                    <td
                      className={`px-6 py-4 text-xs font-medium text-white`}
                      style={{
                        width: "200px !important",
                        maxHeight: expandedRows.includes(item.id)
                          ? "none"
                          : "3em",
                        overflow: "hidden",
                      }}
                    >
                      {(item.details?.length > 50 ||
                        item.user_agent?.length > 50) && (
                        <button
                          className="ml-2 text-gray-200 underline"
                          onClick={() => handleExpandRow(item.id)}
                        >
                          {expandedRows.includes(item.id)
                            ? "Read Less"
                            : "Read More"}
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;

import React, { useEffect, useState } from "react";
import {
  getArtistImage,
  getArtistsPaginatedList,
  getTotalArtists,
} from "../rtsvrf-actions/Actions";
import Pagination from "./Pagination";
import ArtistsTable from "./ArtistsTable";

function ListArtists({ user, unfollowArtist }) {
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [artists, setArtists] = useState([]);
  const [artistImages, setArtistImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const listPerPage = 5;

  useEffect(() => {
    async function fetchPaginationData() {
      if (!user) return;
      setLoading(true);
      const numberOfRecords = await getTotalArtists(
        user?.spotify_followed_artists_id ?? [],
        user?.apple_followed_artists_id ?? []
      );
      setTotal(numberOfRecords);
      const artists = await getArtistsPaginatedList(
        currentPage,
        listPerPage,
        user?.spotify_followed_artists_id ?? [],
        user?.apple_followed_artists_id ?? []
      );
      const supabaseIds = artists.map((artist) => artist.rtsvrf_artist_id);

      if (artists) setArtists(artists);
      const artistsImageData = await getArtistImage(supabaseIds);
      console.log(artistsImageData, "artistsImageData");
      if (artistsImageData) setArtistImages(artistsImageData);
      setLoading(false);
    }
    fetchPaginationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, user]);

  return (
    <div className="w-full my-2 ">
      <ArtistsTable
        loading={loading}
        data={artists}
        unfollowArtist={unfollowArtist}
        artistImages={artistImages}
      />
      {artists?.length ? (
        <div className="py-2 flex items-center justify-center">
          <Pagination
            listPerPage={listPerPage}
            total={total}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      ) : null}
    </div>
  );
}

export default ListArtists;

import React, { useState, useEffect } from "react";
import { getFanProfileById } from "../rtsvrf-actions/Actions";

import Loading from "../rtsvrf-components/Loading";

import { withAuthenticationRequired } from "@auth0/auth0-react";

import { useAuth0 } from "@auth0/auth0-react";
import Navbar from "../rtsvrf-components/Navbar";

function RedirectPage({ userIpData }) {
  const { user, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (user && isAuthenticated) reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isAuthenticated]);

  const reloadData = async () => {
    await getFanProfileById(user.id);
  };

  return (
    <div
      className={
        "bg-gradient-to-r from-gray-400 to-gray-600 sm:flex sm:flex-col px-4 overflow-x-hidden"
      }
    >
      <Navbar userIpData={userIpData} />
      <Loading />
    </div>
  );
}

export default withAuthenticationRequired(RedirectPage, {
  onRedirecting: () => <Loading />,
});
